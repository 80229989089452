import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridEditBooleanCell } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';

const QUESTION_COLUMNS = [
  { field: 'exemplar', headerName: 'Is Exemplar', width: 100 },
  { field: 'theme', headerName: 'Theme', width: 250 },
  { field: 'response_value', headerName: 'Response', width: 1200 },
  {
    field: 'other_themes',
    headerName: 'Other Themes',
    width: 250,
    renderCell: (params) => {
      return <pre>{JSON.stringify(params.value)}</pre>;
    },
  },
];

const ResponsesTable = ({ selectedResponses, openModal, setSelectedResponseIndex }) => {
  const handleRowClick = (params, _, detail) => {
    const rowIndex = detail.api.getRowIndexRelativeToVisibleRows(params.row.id);
    setSelectedResponseIndex(rowIndex);
    openModal(params.row);
  };

  return (
    <DataGridPro
      rows={selectedResponses}
      columns={QUESTION_COLUMNS}
      editMode="row"
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          top: 0,
          zIndex: 1, // Optional: Adjust z-index to ensure header stays above rows
          backgroundColor: 'white', // Optional: Set background color to match the grid
        },
      }}
      disableRowSelectionOnClick
      disableColumnSorting
      disableColumnFilter
      onRowClick={handleRowClick}
    />
  );
};

export default ResponsesTable;
