export const aggregateCounts = (responses) => {
  const counts = {};

  responses.forEach((response) => {
    const questionId = response.question_title;

    if (!counts[questionId]) {
      counts[questionId] = { count: 0, themes: {} };
    }

    counts[questionId].count++;

    response.themes.forEach((theme) => {
      const themeId = theme.theme;

      if (!counts[questionId].themes[themeId]) {
        counts[questionId].themes[themeId] = {
          count: 0,
          exemplar: 0,
          prevalence: 0,
        };
      }

      counts[questionId].themes[themeId].count++;
      if (theme.exemplar) {
        counts[questionId].themes[themeId].exemplar++;
      }
    });
  });

  Object.keys(counts).forEach((questionId) => {
    const question = counts[questionId];
    Object.keys(question.themes).forEach((themeId) => {
      const theme = question.themes[themeId];
      theme.prevalence = Math.floor((100 * theme.count) / question.count);
    });
  });

  return counts;
};

export const tabulizeData = (counts, selectedQuestion, themesShorthands) => {
  return Object.entries(counts[selectedQuestion].themes).map(([theme, data]) => ({
    ...data,
    id: `${selectedQuestion}---${theme}`,
    theme: themesShorthands[parseInt(theme)],
    themeId: theme,
  }));
};

export const tabulizeResponseData = (responses, themesShorthands, selectedTheme) => {
  return responses.flatMap((response) => {
    return response.themes
      .filter((theme) => {
        const themeId = theme.theme;
        return themeId === selectedTheme || selectedTheme === 'all';
      })
      .map((theme) => {
        const themeId = theme.theme;
        if (themeId !== selectedTheme && selectedTheme !== 'all') {
          return null;
        }

        return {
          id: `${response.question_title}---${response.id}---${themeId}`,
          theme: themesShorthands[parseInt(themeId)],
          theme_id: themeId,
          response_value: response.response_value,
          exemplar: theme.exemplar,
          super_exemplar: !!theme.super_exemplar,
          other_themes: response.themes.filter((t) => t.theme !== themeId),
          responseId: response.id,
          themes: response.themes,
          question: response.question_title,
          school: response.participant.school,
        };
      });
  });
};
