import React from 'react';
import Modal from 'react-modal';
import { DefaultModalStyles } from '../../constants/react_modal_styles';
import { useEffect, useRef } from 'react';

const themeTemplate = (themeId) => {
  return { theme: themeId, text_overrides: null, exemplar: false };
};

const addThemeToResponse = (response, themeId) => {
  const newThemes = [...response.themes];
  newThemes.push(themeTemplate(themeId));
  newThemes.filter((theme) => theme.theme === 'themeId');
  return { ...response, themes: newThemes };
};

const removeThemeFromResponse = (response, themeId) => {
  const newThemes = response.themes.filter((theme) => theme.theme !== themeId);

  return { ...response, themes: newThemes };
};

const setThemeSuperExemplar = (response, themeId, exemplar) => {
  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      return { ...theme, super_exemplar: exemplar };
    } else {
      return theme;
    }
  });
  return { ...response, themes: newThemes };
};

const setThemeOverride = (response, themeId, textOverride) => {
  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      if (textOverride === '' || textOverride === response.response_value) {
        return { ...theme, text_overrides: null };
      }
      return { ...theme, text_overrides: textOverride };
    } else {
      return theme;
    }
  });

  return { ...response, themes: newThemes };
};

const setThemeQuestionOverride = (response, themeId, questionOverride) => {
  const usedOverride = questionOverride === '' ? null : questionOverride;

  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      return { ...theme, question_override: usedOverride };
    } else {
      return theme;
    }
  });

  return { ...response, themes: newThemes };
};

const SlideTableNavigator = ({
  close,
  isModalOpen,
  selectedQuestion,
  selectedResponse,
  selectedResponses,
  setSelectedResponseIndex,
  themesShorthands,
  selectedResponseIndex,
  focusedResponse,
  mergeResponse,
  countData,
  allQuestionIds,
}) => {
  const counts = {};
  if (selectedQuestion) {
    countData.forEach((count) => {
      counts[count.themeId] = { total: count.count, exemplar: count.exemplar };
    });
  }

  const firstExemplarInputRef = useRef(null);

  useEffect(() => {
    if (firstExemplarInputRef.current) {
      firstExemplarInputRef.current.focus();
    }
  }, [selectedResponse?.responseId, isModalOpen]);

  const questionOptions = [{ value: '', label: '' }, ...allQuestionIds.map((questionId, index) => ({ value: questionId, label: index + 1 }))];
  return (
    <div id="slideQuestionNavigator">
      <Modal isOpen={isModalOpen} style={DefaultModalStyles} parentSelector={() => document.querySelector('body')} onRequestClose={close}>
        <>
          <h2>Exemplar Responses Navigator</h2>
          <h3>{selectedQuestion}</h3>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {Object.keys(themesShorthands).map((themeId, index) => (
                  <li
                    key={index}
                    style={{ width: '270px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '2em' }}
                  >
                    <div>
                      <input
                        id={`${themeId}-checkbox`}
                        name={`${themeId}-checkbox`}
                        type="checkbox"
                        checked={selectedResponse?.themes.map((t) => t.theme).includes(themeId)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newResponse = addThemeToResponse(selectedResponse, themeId);
                            mergeResponse(newResponse);
                          } else {
                            const newResponse = removeThemeFromResponse(selectedResponse, themeId);
                            mergeResponse(newResponse);
                          }
                        }}
                      />
                      <label htmlFor={`${themeId}-checkbox`}>{themesShorthands[themeId]}</label>
                    </div>
                    <div>
                      ({counts[themeId]?.exemplar || 0}/{counts[themeId]?.total || 0})
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div>
                <div>
                  <button onClick={() => setSelectedResponseIndex((prevIndex) => Math.max(prevIndex - 1, 0))}>{'<'}</button>
                  <span>
                    {selectedResponseIndex + 1} of {selectedResponses.length}
                  </span>
                  <button onClick={() => setSelectedResponseIndex((prevIndex) => Math.min(prevIndex + 1, selectedResponses.length - 1))}>
                    {'>'}
                  </button>
                </div>
              </div>
              <div>
                <span>Response: {selectedResponse && selectedResponse.responseId}</span>
                <table>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Super Exemplar</th>
                      <th>Response</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedResponse &&
                      selectedResponse.themes.map((theme, index) => (
                        <tr key={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}>
                          <td>
                            <select
                              onChange={(e) => {
                                const newResponse = setThemeQuestionOverride(
                                  focusedResponse(selectedResponse.responseId),
                                  theme.theme,
                                  e.target.value
                                );
                                mergeResponse(newResponse);
                              }}
                              defaultValue={theme.question_override || ''}
                            >
                              {questionOptions.map(({ value, label }) => (
                                <option key={value} value={value}>
                                  <div style={{ maxWidth: '20em' }}>{label}</div>
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              ref={index === 0 ? firstExemplarInputRef : null}
                              name={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}
                              id={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}
                              type="checkbox"
                              checked={!!theme.super_exemplar}
                              onChange={(e) => {
                                const newResponse = setThemeSuperExemplar(
                                  focusedResponse(selectedResponse.responseId),
                                  theme.theme,
                                  e.target.checked
                                );
                                mergeResponse(newResponse);
                              }}
                            />
                            <label htmlFor={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}>
                              {themesShorthands[theme.theme]}
                            </label>
                          </td>
                          <td>
                            <textarea
                              rows={3}
                              data-override
                              style={{ width: '857px' }}
                              onChange={(e) => {
                                const newResponse = setThemeOverride(focusedResponse(selectedResponse.responseId), theme.theme, e.target.value);
                                mergeResponse(newResponse);
                              }}
                              value={theme.text_overrides || selectedResponse.response_value}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default SlideTableNavigator;
